@font-face {
  font-family: Hammock;
  src: url(fonts/HAMMOCK-Black.otf);
}

body, html, #root {
  background-color: #eeeeee);
  margin: auto;
  min-height: 100%;
  height: 100vh;
  font-family: 'Hammock', sans-serif;
  color: #363534;
  letter-spacing: 3px;
}

.App {
  min-height: 100vh;
  /*height: 100vh;*/
  text-align: center;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20em;
  padding-left: 20em;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  z-index: 0;
  position: relative;
}

.home-nav {
  font-size: 20px;
}

.tattoo-nav {
  justify-content: space-around;
  margin: 0 15%;
}

.tattoo-nav button {
  width: 120px;
}

.home-wrapper {
  height: 80vh;
  margin: 2% 0;
}

.home-picture {
  height: 100%;
}

.logo a {
  text-decoration: none;
  padding: 0;
  border-radius: 3px;
  border: 1px solid transparent;
  margin-top: 0px;
  transition: .2s all
}

h1 {
  font-size: 5em;
  margin: 2%;
  letter-spacing: auto;
  line-height: 50px;
  margin-bottom: 30px;
}

h2 {
  font-size: 4em;
  letter-spacing: 4px;
  line-height: 40%;
  margin: 2%;
  bottom: 50%;
}

button {
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  padding: 3px 4px;
  letter-spacing: 2px;
  border-radius: 3px;
  border: 1px solid #363534;
}

nav a, nav button {
  text-transform: uppercase;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid transparent;
  width: 100px;
  text-decoration: none;
}

.home-nav a {
  width: auto;
}

button,
nav a,
.logo a {
  color: #363534;
  text-decoration: none;
}

.active {
  border: 1px solid #363534;
}

button:hover,
nav a:hover {
  cursor: pointer;
  border: 1px solid #7a7875;
  transition: .2s all;
}

button:hover {
  background-color: #f0ecec;
}

nav button {
  /* border: 1px solid #dfdddd; */
  letter-spacing: 3px;
  font-size: 14px;
}

nav button:hover {
  background-color: inherit;
}

.logo a:hover {
  text-shadow: 0px 5px 1px lightgray;
}

.logo a:active {
  text-shadow: 0px 4px 1px lightgray;
}

button:focus {
  outline: none;
}

button:active,
nav li:active {
  cursor: pointer;
  border: 1px solid #363534;
  outline: none;
}

img {
  object-fit: cover;
  margin: 10px;
  transition: .2s all;
}

.about-me {
  margin: 0 20% 0% 20%;
}

.bio {
  padding-top: 0px;
  text-align: justify;
  font-size: 16px;
  /* text-indent: 36px; */
  line-height: 25px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
}

.headshot {
  width: 350px;
  float: left;
  padding-right: 15px;
}

.art-wrapper {
  margin: 0 15%;
}

.art-image {
  width: 200px;
  height: 200px;
}

.carousel img {
  margin: 0;
}

.carousel .slide .legend {
  font-family: 'Roboto';
  bottom: 0 !important;
  margin-bottom: 0 !important;
  height: auto;
  transition: all .35s ease-in-out !important;
  padding: none !important;
}

.carousel-title {
  font-size: 20px;
  font-family: 'Hammock';
}

.admin-false {
  width: 130px;
  height: 130px;
}

.admin-true {
  width: 130px;
  height: 130px;
  box-shadow: 0px 5px 1px rgb(154, 246, 246);
}

.admin-true:hover,
.admin-false:hover,
.art-image:hover {
  box-shadow: 0px 5px 1px lightgray;
  cursor: pointer;
}

.admin-zoom {
  width: 300px;
  height: auto;
}

.show-more {
  margin-bottom: 5%;
}

.image-upload-wrapper,
.image-view-wrapper {
  display: block;
  background-color: rgba(36, 36, 36, 0.47);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 3px;
}

.image-upload, .image-view, .image-carousel {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 10px;
  border-radius: 3px;
  width: 40%;
  height: auto;
  box-shadow: 0px 3px 10px gray;
}

.image-carousel {
  margin: 8% auto;
  padding-bottom: 0 20px 20px 20px;
  max-width: 40%;
}

.image-view {
  height: auto;
  width: 350px;
  display: block;
  justify-content: center;
}

.image-form {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}

.image-form input {
  width: 60%;
}

label {
  font-family: 'Roboto';
}

.description {
  height: 100px;
  width: 60%;
  margin: 15px;
}

.close {
  float: right;
  display: block;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  border-radius: 3px;
}

.close:hover {
  background-color: #f7b7b7;
  cursor: pointer;
}

.auth-form {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-form form {
  width: 20%;
}

.auth-form form input {
  display: block;
  justify-content: center;
  width: 100%;
  margin: 0;
}

input {
  height: 25px;
  margin: 15px;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

textarea {
  width: 25%;
  margin-top: 20px;
}

.logout-button {
  margin-top: 10px;
}

.delete {
  margin: 0 10px;
}

.contact-form {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error-message {
  color: rgb(251, 144, 108);
  font-family: 'Roboto';
  margin-top: 0;
}

.message-sent {
  font-family: 'Roboto'
}

.contact-blurb {
  font-size: 14px;
  /* text-indent: 36px; */
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  margin: 0 30% 2% 30%;
  text-align: justify;
}

.contact-icon {
  font-size: 50px !important;
  color: #363534;
  margin: 0;
  text-decoration: none;
  display: inline;
  padding: 0 25px;
}

.contact-icon:hover {
  text-shadow: 0px 5px 1px lightgray;
  text-shadow: 0px 4px 1px lightgray;
  transition: .2s all
}

.social {
  display: inline-flex;
  justify-content: space-between;
}

.icon-label {
  margin: 0  0 10px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 8px;
}

@media (max-width: 800px) {
  body {
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  nav {
    flex-direction: column;
    padding: 0;
    width: 100%;
    margin: 5% 0;
  }

  .logo {
    margin-bottom: 0;
  }

  .home-nav {
    margin: 0;
    width: 100vw;
  }

  nav a {
    font-size: 16px;
    margin: 0;
    border: 1px solid #eeeeee;
    padding: 2px;
    width: auto;
  }

  .home-nav a {
    font-size: 28px;
    width: 100vw;
    margin: 4% 0;
  }

  .tattoo-nav {
    justify-content: space-around;
    margin: 0;
  }

  .tattoo-nav button {
    width: auto;
  }

  .home-wrapper {
    width: 100vw;
    margin: 0;
    height: auto;
  }

  .home-picture {
    width: 100%;
    height: auto;
    margin: 0;
  }

  .art-wrapper {
    margin: 0;
  }

  .art-image {
    width: 100px;
    height: 100px;
  }

  .about-me {
    margin: 0 15% 15% 15%;
    display: block;
  }

  .headshot {
    width: 90%;
    padding-right: 0;
    float: none;
  }

  .bio {
    line-height: normal;
    letter-spacing: normal;
    padding: 0;
    text-align: justify;
  }

  .image-carousel {
    width: 100%;
    height: auto;
    max-width: 90vw;
  }

  .carousel .slide .legend {
    visibility: hidden;
  }

  input {
    width: 80%
  }

  textarea {
    width: 80%;
  }

  .contact-blurb {
    margin: 0 5% 5% 5%;
  }
}
